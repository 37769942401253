import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { useStaticQuery, graphql } from "gatsby"


const query = graphql`
{
  allAnimation {
    edges {
      node {
        name,
        lottiData
      }
    }
  }
}
`

export const LoopingAnimation = ({ name }) => {

  const data = useStaticQuery(query)
  const { allAnimation: { edges = [] } } = data;
  const icon = edges.map((a) => a.node).find(a => a.name === name);



  const ref = useRef(null);
  let animation = null;

  useEffect(() => {
    if(!icon) {
      return (null);
    }
    // eslint-disable-next-line
    animation = lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      animationData: JSON.parse(icon.lottiData),
      autoplay: true,
      loop: true,
    })
    return () => {
      animation.destroy();
    }
  }, [ref])


  return (
    <div>
      <div ref={ref} />
    </div>
  )

}