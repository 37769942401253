import React from 'react';

import './our-work-heading.scss';
import { LoopingAnimation } from '../looping-animation';

export const OurWorkHeading = ({icon, title, description}) => {
  return (
    <div className="our-work-heading">
      <div>
        <LoopingAnimation name={icon} />
      </div>
      <div>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </div>
  )
}