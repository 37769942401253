import React, { useState, useEffect } from "react";
import { drop } from "lodash";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Image from '../Image';

import "./media-slider.scss";

export function MediaSlider({ data }) {
  data = data.map((item, index) => ({...item, position: index}));
  const [offset, setOffset] = useState(0);
  const [ columns, setColumns ] = useState(2);

  useEffect(() => {
    const handler = (a) => setColumns(a.matches ? 1 : 2);
    const query = window.matchMedia('(max-width: 1000px)');
    handler(query);
    query.addListener(handler);
  }, [])

  const items = drop(data, offset).slice(0, columns);

  const maxOffset = columns === 1 ? data.length - 1 : data.length - 1 - 1;

  const moveOffsetBack = () => {
    setOffset(offset <= 0 ? 0 : offset - 1);
  };

  const moveOffsetForward = () => {
    setOffset(offset >= maxOffset ? maxOffset : offset + 1);
  };

  return (
    <div className="media-slider-container">
      <button onClick={moveOffsetBack}>
        <svg width="19px" height="32px" viewBox="0 0 19 32">
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <g
              id="Hitchbot"
              transform="translate(-41.000000, -7380.000000)"
              stroke="#000000"
              stroke-width="5"
            >
              <polyline
                id="Stroke-1-Copy"
                transform="translate(50.500000, 7396.000000) scale(-1, 1) translate(-50.500000, -7396.000000) "
                points="44 7409 57 7396 44 7383"
              />
            </g>
          </g>
        </svg>
      </button>
      <TransitionGroup className="thumbnails">
        {items.map((item, index) => {
          const className = index === 0 ? "small" : "large";
          return (
            <CSSTransition
              key={item.position}
              timeout={200}
              className={className}
            >
              <div key={item.position}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <Image path={item.placeholder} fluid />
                </a>
                <p className="title">{item.title}</p>
                <p>{item.description}</p>
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
      <button onClick={moveOffsetForward}>
        <svg width="19px" height="32px" viewBox="0 0 19 32">
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <g
              id="Hitchbot"
              transform="translate(-1383.000000, -7380.000000)"
              stroke="#000000"
              stroke-width="5"
            >
              <polyline id="Stroke-1" points="1386 7409 1399 7396 1386 7383" />
            </g>
          </g>
        </svg>
      </button>
    </div>
  );
}