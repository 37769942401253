import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const query = graphql`
{
  allImageSharp{
    edges{
      node {
        fluid(maxWidth: 4000){
          ...GatsbyImageSharpFluid_withWebp_noBase64
          originalName
        }
        fixed(width: 200){
          ...GatsbyImageSharpFixed_withWebp_noBase64
          originalName
        }
      }
    }
  }
}
`

const Image = ({ path, className, fixed = false }) => {
  const { allImageSharp: {edges} } = useStaticQuery(query)

  const props = {}

  if(fixed){
    const image = edges.find(a => {
      return a.node.fixed.originalName === path;
    })
    props.fixed = image.node.fixed;  
  } else {
    const image = edges.find(a => {
      return a.node.fluid.originalName === path;
    })
    props.fluid = image.node.fluid;  
  }

  return (
    <Img className={className} {...props} />
  )
}
export default Image
