import React from "react"
import showdown from "showdown"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { OurWorkHeading } from "../components/our-work-heading"
import { WorkList } from "../components/work-list"
import { Counters } from "../components/counters"
import { MediaSlider } from "../components/media-slider"

import "./our-work.scss"
// import Image from "../components/Image"

const converter = new showdown.Converter()

const IndexPage = ({ pageContext }) => {
  const data = JSON.parse(pageContext.pageData)
  return (
    <Layout>
      <SEO
        title={data.title}
        keywords={[`gatsby`, `application`, `react`]}
        description={data.description}
      />
      <OurWorkHeading
        icon={data.icon_name}
        title={data.title}
        description={data.description}
      />
      {data.counters && <Counters counters={data.counters} />}

      {data.section_1 && (
        <div className="section-1">
          <div>
            <div />
            <div>
              <img
                className="image"
                src={require("../images/Project_Page-Asset_01.png")}
                alt=""
              />
            </div>
          </div>
          <div>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(data.quote || ""),
                }}
              />
            </div>
            <div>
              <h2>{data.section_1.title}</h2>  
              {data.section_1.content && <p dangerouslySetInnerHTML={{__html: converter.makeHtml(data.section_1.content)}}></p>}
              {/* <p>{data.section_1.content}</p>  */}
            </div>
          </div>
          <img
            className="image"
            src={require("../images/Project_Page-Asset_02.png")}
            alt=""
          />
        </div>
      )}

      {data.section_2 && (
        <div className="section-2">
          <div className='content'>
            <h2>{data.section_2.title}</h2>            
            {data.section_2.content && <p dangerouslySetInnerHTML={{__html: converter.makeHtml(data.section_2.content)}}></p>}
            <img
              className="image"
              src={require("../images/Project_Page-Asset_03.png")}
              alt=""
            />
          </div>
          <div className="images">
            <div className="image-1">
              <img src={require(`../images/${data.section_2.image_1}`)} alt="" />
            </div>
            <div className="image-2">
              <img src={require(`../images/${data.section_2.image_2}`)} alt=""/>
            </div>
          </div>
        </div>
      )}

      {data.section_3 && (
        <div className="section-3">
          <div>
            <h2>{data.section_3.title}</h2>
            {data.section_3.content_1 && <p dangerouslySetInnerHTML={{__html: converter.makeHtml(data.section_3.content_1)}}></p>}
            {data.section_3.image && (
              <img
                src={require(`../images/${data.section_3.image}`)}
                alt={data.section_3.title}
              />
            )}
            {data.section_3.content_2 && <p dangerouslySetInnerHTML={{__html: converter.makeHtml(data.section_3.content_2)}}></p>}
          </div>
        </div>
      )}

      {data.section_4 && (
        <div className="section-4">

          <div className="column">
            <div className="asset-container">
              <div className="asset-inner">
                <img className="asset" src={require("../images/Project_Page-Asset_04.png")} alt="section-3-spacer" />
              </div>
            </div>
            <div>
              <h2>{data.section_4.title}</h2>
              {data.section_4.content && <p dangerouslySetInnerHTML={{__html: converter.makeHtml(data.section_4.content)}}></p>}
            </div>
          </div>
          <div className="column">
            <img
              className="image"
              src={require(`../images/${data.section_4.image}`)}
              alt={data.section_4.title}
              />
          </div>
        </div>
      )}
      
      {data.section_5 && (
        <div className="section-5">
          <div className="row">
            <div>
              <img className="image" src={require("../images/Project_Page-Asset_10.png")} alt="section-5-spacer" />
            </div>
            <div />
          </div>
          <div className="row">
            <div>
              <div className="title">
                <h2>{data.section_5.title}</h2>
              </div>
              <div className="proto">
                <h3>{data.section_5.title_1}</h3>
                {data.section_5.content_1 && <p dangerouslySetInnerHTML={{__html: converter.makeHtml(data.section_5.content_1)}}></p>}
              </div>
              <div className="conn">
                <h3>{data.section_5.title_2}</h3>
                {data.section_5.content_2 && <p dangerouslySetInnerHTML={{__html: converter.makeHtml(data.section_5.content_2)}}></p>}
              </div>
              <div className="comm">
                <h3>{data.section_5.title_3}</h3>
                {data.section_5.content_3 && <p dangerouslySetInnerHTML={{__html: converter.makeHtml(data.section_5.content_3)}}></p>}
              </div>
                        
            </div>
            {/* <div>
              <img
                src={require(`../images/${data.section_5.image}`)}
                alt={data.section_5.title}
              />
            </div> */}
          </div>
        </div>
      )}

    {data.section_6 && (
        <div className="section-6">
          <div>
            <img className="image" src={require("../images/Project_Page-Asset_06.png")} alt="section-3-spacer" />
          </div>
          <div>
            <h2>{data.section_6.title}</h2>
            {data.section_6.content && <p dangerouslySetInnerHTML={{__html: converter.makeHtml(data.section_6.content)}}></p>}
          </div>
        </div>
      )}

      {data.media && (
        <div className="media-section">
          <img
            className="image"
            src={require("../images/Project_Page-Asset_07.png")}
            alt=""
          />
          <h2>In The Media</h2>
          <MediaSlider data={data.media} />
        </div>
      )}
      <div className="work-section">
        <img
          className="image"
          src={require("../images/Project_Page-Asset_08.png")}
          alt=""
        />
        <h2>Other Projects</h2>
        <div className="work-list">
          <WorkList current={pageContext.slug} />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
