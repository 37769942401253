import React from 'react';
import './counters.scss'
export const Counters = ({ counters = [] }) => {
  return (
    <div className="counters">
      {counters.map(counter => (
        <div
          className="counter"
          style={{
            flex: `0 0 ${100/counters.length}%`,
            width: `${100/counters.length}%`
          }}
        >
          <p className="value">{counter.value.toLocaleString()}</p>
          <p className="label">{counter.label}</p>
        </div>
      ))}
    </div>
  )
}